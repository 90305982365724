import React from "react";
import Texts from "../../../utils/lang-utils";
import paymentsImage from "../../../areas/main/assets/images/resources/qr-payment-in-restaurant.jpg";
import urls from "../../../areas/main/assets/urls";

const Payments = () => {
  return (
    <>
      <section className="cta-two">
        <div className="container">
          <div className="cta-two__moc">
            {/* <img src={mocImage} style={{
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} /> */}


          </div>
          <div className="row h-100">


            <div className="col-lg-6">
              <div className="cta-two__content">
                <div className="block-title text-left">
                  <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.PaymentsTitle }}></h2>
                </div>
                <img src={paymentsImage} alt="alter text" className="mobile-show" style={{
                      display: 'none',
                      marginBottom: '40px',
                      maxWidth: '100%',
                      borderRadius: 20,
                      boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
                }} />
                <h4><i className="fa fa-check"></i> {Texts.QROrdering.PaymentsItem1Title}</h4>
                <p dangerouslySetInnerHTML={{ __html: Texts.QROrdering.PaymentsItem1Text }}></p>
                <h4><i className="fa fa-check"></i> {Texts.QROrdering.PaymentsItem2Title}</h4>
                <p dangerouslySetInnerHTML={{ __html: Texts.QROrdering.PaymentsItem2Text }}></p>
                <h4><i className="fa fa-check"></i> {Texts.QROrdering.PaymentsItem3Title}</h4>
                <p dangerouslySetInnerHTML={{ __html: Texts.QROrdering.PaymentsItem3Text }}></p>

                <a href={urls.onboarding} className="thm-btn mt-3 mobile-hide">
                  <span>{Texts.GetStarted}</span>
                </a>
              </div>
            </div>
            <div className="col-lg-6 mobile-hide d-none d-xl-block" style={{ marginTop: 'auto', marginBottom: 'auto' }}>

            <img src={paymentsImage} alt="alter text" style={{
                  maxWidth: '100%',
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} />
            </div>
          </div>
          <a href={urls.onboarding} className="thm-btn mt-3 mobile-show" style={{ display: 'none' }}>
            <span>{Texts.GetStarted}</span>
          </a>
        </div>
      </section>
    </>

  );
};
export default Payments;
